@charset 'UTF-8';

// Breadlist
// ---------
.breadlist-wrapper {
  background: transparent;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
}

.breadlist {
  background: transparent;
  border-radius: .4rem;
  display: flex;
  font-size: 0.725em;
  padding: 1em 0;

  li {
    color: $gray-dark;
    line-height: 1.1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '＞';
      color: $gray;
      padding-right: .5em;
      padding-left: .5em;
    }

    a {
      color: $second-color;
      &::active, &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $gray;
    }
  }
}
