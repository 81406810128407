@charset 'UTF-8';

//
// Header Variables
// ----------------

// .header-container の横幅 .container と合わせたい場合は $container-width を使用する
$header-container-width: (
  'sm': map( $container-width, sm ),
  'md': map( $container-width, md ),
  'lg': map( $container-width, lg )
) !default;

// .header-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$header-container-padding: (
  'sm': map( $container-padding, sm ),
  'md': map( $container-padding, md ),
  'lg': map( $container-padding, lg )
) !default;

// headerの高さ（指定：px）
$height-header: (
  sm: 44,
  md: 55,
  lg: 80
  ) !default;

$background-header: #fff !default; // header の background-color


// Header
// ------
header {
  width: 100%;
  z-index: map($z-index, header);
}

.header-wrap {
  background: $background-header;
  position: relative;
}

.header-container {
  margin: 0 auto;
  padding: 0 map( $header-container-padding, sm );
  width: map( $header-container-width, sm );
  @include media(md) {
    padding: 0 map( $header-container-padding, md );
    width: map( $header-container-width, md );
  }
  @include media(lg) {
    padding: 0 map( $header-container-padding, lg );
    width: map( $header-container-width, lg );
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map($height-header, sm, rem);
  @include media(md) {
    height: map($height-header, md, rem);
  }
  @include media(lg) {
    height: map($height-header, lg, rem);
  }

  .header-inner-left {

    .header-title {
      font-size: 1.6rem;
      font-weight: 800;
      @include media(md) {
        font-size: 1.6rem;
      }
      @include media(lg) {
        font-size: 2.0rem;
      }
      & a {
        color: $gray-dark;
      }
    }
  }

  .header-inner-right {

  }
}
