@charset 'UTF-8';

// Icon extend
// -----------
%icon-default {
  font-family: $icon-font-family;
  content: map-get( $icon-type, 'arrows' );
  color: inherit;
  display: inline-block;
  width: 1.28571429em;
  text-align: center;
}

// Icon
// ----
.icon {
  > span {
    &::after {
      @extend %icon-default;
      margin-left: .2em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @extend %icon-default;
        margin-right: .1em;
      }
    }
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
  }
  // color pattern
  @each $name, $color in $color-pattern {
    & span.ic-#{$name} {
      &::after, &::before {
        color: $color;
      }
    }
  }
}
