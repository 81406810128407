@charset 'UTF-8';

$table-border-color : #ccc !default;    // tableのボーダーの色
$table-th-bg-color  : #fbfbf6 !default; // th のバックグラウンドカラー
$table-td-bg-color  : #fff !default;    // td のバックグラウンドカラー

// Table
// -----
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;

  & th, td {
    border: 1px solid $table-border-color;
    padding: 0.5em 1em;
  }

  & th {
    background: $table-th-bg-color;
  }
  & td {
    background: $table-td-bg-color;
  }
}

// th block
.th-block {
  border-bottom: 1px solid $table-border-color;
  @include media(md) {
    border: 1px solid $table-border-color;
  }
  th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th {
    width: 100%;
    @include media(md) {
      width: 15%;
    }
  }
  td {
    border-top: none;
    border-bottom: none;

    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
}

// スクロールを出すためのwrapper
// -----------------------
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}
