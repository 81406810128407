@charset 'UTF-8';

// Label
// -----
.label {
  background-color: $base-color;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: inherit;
  line-height: 1 !important;
  padding: .6em;
  text-align: center;
  position: relative;

  // bacground color pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      background: $color;
    }
  }

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;

    // border color pattern
    @each $name, $color in $color-pattern {
      &.#{$name} {
        border: 1px solid $color;
        color: $color;
      }
    }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: .5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: .5em;
  }
}
