@charset 'UTF-8';

// Common Font size
// ----------------
$font-size: (
  'exlarge': 200%,
  'xxlarge': 150%,
  'xlarge':  125%,
  'large':   112.5%,
  'medium':  87.5%,
  'small':   75%,
  'xsmall':  62.5%
  ) !default;

@each $name, $size in $font-size {
  .#{$name} {
    font-size: $size !important;
  }
}


// Heading
// -------
$h1-font-size:   2.0em;
$h2-font-size:   1.8em;
$h3-font-size:   1.6em;
$h4-font-size:   1.4em;
$h5-font-size:   1.2em;
$h6-font-size:   1.0em;
//
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}


// Paragraph
// ---------
// p タグ内の letter-spacing（単位は自由）
$paragraph-letter-spacing: (
  sm: 0,
  md: 0,
  lg: 0
  ) !default;

// p タグ内の line-height
$paragraph-line-height: (
  sm: 1.6,
  md: 1.6,
  lg: 1.8
  ) !default;

// 段落の余白（単位：em）
$paragraph-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

p {
  font-size: inherit;
  letter-spacing: map($paragraph-letter-spacing, sm);
  line-height: map($paragraph-line-height, sm);
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map($paragraph-letter-spacing, md);
    line-height: map($paragraph-line-height, md);
  }
  @include media(lg) {
    letter-spacing: map($paragraph-letter-spacing, lg);
    line-height: map($paragraph-line-height, lg);
  }
}

p + p {
  margin-top: map($paragraph-margin, sm);
  @include media(md) {
    margin-top: map($paragraph-margin, md);
  }
  @include media(lg) {
    margin-top: map($paragraph-margin, lg);
  }
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}
