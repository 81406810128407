@charset 'UTF-8';

//
// Block Variables
// ----------------
$block-border: 4px solid $base-color; // block の border
$block-border-radius: 8px; // block の border-radius
$block-box-shadow: 0 0 4px rgba( $base-color, .5 ); // block の box-shadow

// Block
// -----
.block {
  background: #fff;
  border: $block-border;
  border-radius: $block-border-radius;
  box-shadow: $block-box-shadow;
  overflow: hidden;

  &.height {
    height: 100%;
  }

  // border color pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      border: 1px solid $color;
    }
  }
}

.block-header {
  background: $base-color;
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  padding: .3em 1em;

  // bacground color pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      background: $color;
    }
  }
}

.block-body {
  padding: 1em;
}
