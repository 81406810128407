@charset 'UTF-8';

// Color
// -----

// ベースカラーパターン
$base-color:    #0051A3 !default; // ベースカラー
$first-color:   #1b4892 !default;
$second-color:  #006a76 !default;
$third-color:   #ff6600 !default;
$fourth-color:  #993399 !default;
$five-color:    #00ccff !default;
// グレーパターン
$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #dfe2e4 !default;
$gray-lightest: #eceeef !default;
// 汎用カラー
$white-color:  #fff    !default; // 白
$black-color:  #000    !default; // 黒
$red-color:    #b0120a !default; // 赤
$yellow-color: #fff000 !default; // 黄
$orange-color: #f18f2c !default; // 橙
$green-color:  #63a764 !default; // 緑
$blue-color:   #005fcc !default; // 青
$pink-color:   #ff0066 !default; // ピンク
//
// 上記でカラーを追加した場合、下記の配列にも追加する（ '使用する名前': 変数;
//
$color-pattern: (
  'base':          $base-color,
  'first':         $first-color,
  'second':        $second-color,
  'third':         $third-color,
  'fourth':        $fourth-color,
  'five':          $five-color,
  'gray-dark':     $gray-dark,
  'gray':          $gray,
  'gray-light':    $gray-light,
  'gray-lighter':  $gray-lighter,
  'gray-lightest': $gray-lightest,
  'white':         $white-color,
  'black':         $black-color,
  'red':           $red-color,
  'yellow':        $yellow-color,
  'orange':        $orange-color,
  'green':         $green-color,
  'blue':          $blue-color,
  'pink':          $pink-color
) !default;

// transition（タイミングを統一したい場合に使用
// -----------------------------------
$transition: 0.25s ease-out;

// Icon（種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// -----------------------------------------------------
$icon-font-family: 'Font Awesome 5 Free';
$icon-type: (
  'arrows':  '\f138',
  'pdf':     '\f1c1',
  'window':  '\f2d2',
  'tel':     '\f098',
  'mail':    '\f003',
  'fax':     '\f1ac',
  'privacy': '\f13e',
  'link':    '\f0c1'
) !default;

// header & main & footer の z-index
// --------------------------------
$z-index: (
  'header': 2,
  'main'  : 0,
  'footer': 1
) !default;
