@charset 'UTF-8';

//
// Breakpoint Variables
// --------------------

$breakpoints: (
  'md' : 760,
  'lg' : 980
  ) !default;

// 単位を em に変換
// -------------
@function convertEm( $width ) {
  @return '#{ $width / 16 }em';
}

// メディアクエリの書き出し
// ------------------
@mixin media($data) {
  $key: map-get( $breakpoints, $data );
  @media screen and ( min-width: convertEm($key) ) {
    @content;
  }
}
