@charset 'UTF-8';

// Preferences Variables
// ---------------------

// Font family
$font-family: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;

// body の text color
$body-text-color: #333 !default;

// body の background-color
$background-body: #fff !default;

// ベースの文字サイズ（指定：px）
$base-font-size: (
  sm: 12,
  md: 14,
  lg: 16
) !default;

// body に設定する letter-spacing（単位は自由）
$body-letter-spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;

// ベースのline-height
$base-line-height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.6
) !default;

// ハイパーリンク
$link-color            : #0033cc !default;
$link-decoration       : none !default; // or underline
$link-hover-color      : darken( $link-color, 15% ) !default;
$link-hover-decoration : none !default; // or underline

// Horizontal Line
$hr-border: 1px solid $gray-lighter !default; // ボーダーの種類
// ボーダー上下の間隔
$hr-margin: (
  sm: 1em,
  md: 1em,
  lg: 1em
) !default;

// HTMLページの初期設定
// ----------------
* {
  box-sizing: border-box;
}

html {
  font-size: calc( 1em * 0.625 ); // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  background: $background-body;
  color: $body-text-color;
  font-family: $font-family;
  font-size: map($base-font-size, sm, px);
  line-height: map($base-line-height, sm);
  letter-spacing: map($body-letter-spacing, sm);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  //font-feature-settings: 'pkna' 1;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @include media(md) {
    font-size: map($base-font-size, md, px);
    letter-spacing: map($body-letter-spacing, md);
    line-height: map($base-line-height, md);
  }
  @include media(lg) {
    font-size: map($base-font-size, lg, px);
    letter-spacing: map($body-letter-spacing, lg);
    line-height: map($base-line-height, lg);
  }
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
// IE11 用 hack
_:-ms-lang(x), body { display: flex; }


// image
// -----
img {
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

// hyper link
// ----------
a {
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Horizontal Line
// ---------------
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  width: 100%;
  height: 0;
  margin: map($hr-margin, sm) auto;
  clear: both;
  @include media(md) {
    margin: map($hr-margin, md) auto;
  }
  @include media(lg) {
    margin: map($hr-margin, lg) auto;
  }
}
