@charset 'UTF-8';

//
// Layout Variables
// ----------------

// .container の横幅
$container-width: (
  'sm': 100%,
  'md': 100%,
  'lg': 960px
) !default;

// .container の左右の padding
$container-padding: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
) !default;

$background-container: transparent !default; // container の background-color

// .section-inner-upper .section-inner .section-inner-lower 上下の余白（単位：em）
$section-gutter: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
  ) !default;

// .element 上下の余白（単位：em）
$element-gutter: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
  ) !default;

// Common
// ------
.common-wrapper {
  position: relative;
  width: 100%;
}

.common-column {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  header, footer {
    flex: none;
  }
  main {
    width: 100%;
    flex: 1 0 auto;
    min-height: 0%; // IE11用
  }
}

// Container
// ---------
.container {
  background: $background-container;
  margin: 0 auto;
  padding: 0 map( $container-padding, sm );
  width: map( $container-width, sm );
  @include media(md) {
    padding: 0 map( $container-padding, md );
    width: map( $container-width, md );
  }
  @include media(lg) {
    padding: 0 map( $container-padding, lg );
    width: map( $container-width, lg );
  }
}

// IE11 hack
// ---------
_:-ms-lang(x), .container { flex: 0; }
_:-ms-lang(x), .row-container { flex: 0; }

// section-inner
// -------------
//
.section-inner {
  padding-top: map($section-gutter, sm);
  padding-bottom: map($section-gutter, sm);
  @include media(md) {
    padding-top: map($section-gutter, md);
    padding-bottom: map($section-gutter, md);
  }
  @include media(lg) {
    padding-top: map($section-gutter, lg);
    padding-bottom: map($section-gutter, lg);
  }
}

.section-inner-upper {
  padding-bottom: map($section-gutter, sm);
  @include media(md) {
    padding-bottom: map($section-gutter, md);
  }
  @include media(lg) {
    padding-bottom: map($section-gutter, lg);
  }
}

.section-inner-lower {
  padding-top: map( $section-gutter, sm );
  @include media(md) {
    padding-top: map( $section-gutter, md );
  }
  @include media(lg) {
    padding-top: map( $section-gutter, lg );
  }
}

// Element
// -------
.element {
  padding-top: map( $element-gutter, sm );
  @include media(md) {
    padding-top: map( $element-gutter, md );
  }
  @include media(lg) {
    padding-top: map( $element-gutter, lg );
  }
}
