@charset 'UTF-8';

// ボタンの中 上下の padding
$button-padding-tb: (
  sm: 1em,
  md: .8em,
  lg: .8em
  ) !default;

// ボタンの中 左右の padding
$button-padding-lr: (
  sm: 1em,
  md: .8em,
  lg: .8em
  ) !default;

$button-border-radius: 2px !default; // 角丸
$button-border: 1px !default;        // アウトライン時のボーダーの太さ


//
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

//
.button {
  background-color: $base-color;
  border: 1px solid darken( $base-color, 20% );
  border-radius: $button-border-radius;
  color: #fff;
  display: inline-block;
  font-size: 1em;
  line-height: 1 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background-color $transition, color $transition;
  vertical-align: middle;
  padding: map( $button-padding-tb, sm ) map( $button-padding-lr, sm );
  @include media(md) {
    padding: map( $button-padding-tb, md ) map( $button-padding-lr, md );
  }
  @include media(lg) {
    padding: map( $button-padding-tb, lg ) map( $button-padding-lr, lg );
  }
  // Hover
  &:hover {
    background-color: darken( $base-color, 15% );
    color: #fff;
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      background-color: $color;
      border: 1px solid $color;
      &:hover {
        background-color: darken( $color, 10% );
      }
    }
  }
} // .button

//
// Outline
//
.button.outline {
  background-color: #fff;
  border: $button-border solid $base-color;
  color: $base-color;
  text-shadow: none;
  // Hover
  &:hover {
    background-color: lighten( $base-color, 40% );
  }
  // Color Pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      background-color: #fff;
      border: 1px solid $color;
      color: $color;
      &:hover {
        background-color: rgba( $color, .1 );
        //color: if($color == $yellow-color, $gray, $color);
      }
    }
  }
}

//
// Expanded
//
.button.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

%icon-default {
  font-family: 'Font Awesome 5 Free';
  content: map-get( $icon-type, 'arrows' );
  color: inherit;
  display: inline-block;
  width: 1.28571429em;
  text-align: center;
}
//
// Button
//
.button,
.button.outline {
  & span {
    &::after {
      @extend %icon-default;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @extend %icon-default;
        margin-right: .4em;
      }
    }
    // icon-type
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
    // icon color
    @each $name, $color in $color-pattern {
      &.ic-#{$name} {
        &::after, &::before {
          color: $color;
        }
      }
    }
  } // span
}
