@charset 'UTF-8';

//
// 配列に単位をつける
@function map($value, $key, $unit: null) {
  @if( $unit == rem ) {
    @return map-get($value, $key) / 10 + $unit;
  } @else if( $unit == null ) {
    @return map-get($value, $key);
  } @else {
    @return map-get($value, $key) + $unit;
  }
}
