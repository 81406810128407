@charset 'UTF-8';

//
// Footer Variables
// ----------------

// .footer-container の横幅 .container と合わせたい場合は $container-width を使用する
$footer-container-width: (
  'sm': map( $container-width, sm ),
  'md': map( $container-width, md ),
  'lg': map( $container-width, lg )
) !default;

// .footer-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$footer-container-padding: (
  'sm': map( $container-padding, sm ),
  'md': map( $container-padding, md ),
  'lg': map( $container-padding, lg )
) !default;

// <main> と <footer> の間隔
$footer-padding-top: (
  sm: 4em,
  md: 4em,
  lg: 4em
) !default;

$background-footer: #f2f3f7 !default; // footer の background-color

// Footer
// ------
footer {
  flex: none;
  min-width: 320px;
  padding-top: map( $footer-padding-top, sm );
  width: 100%;
  z-index: map( $z-index, footer );
  @include media(md) {
    padding-top: map( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  position: relative;
}

.footer-container {
  margin: 0 auto;
  padding: 0 map( $footer-container-padding, sm );
  width: map( $footer-container-width, sm );
  @include media(md) {
    padding: 0 map( $footer-container-padding, md );
    width: map( $footer-container-width, md );
  }
  @include media(lg) {
    padding: 0 map( $footer-container-padding, lg );
    width: map( $footer-container-width, lg );
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;

  & .footer-title {
    color: $gray-dark;
    font-size: 1.6rem;
    font-weight: 800;
    @include media(md) {
      font-size: 1.6rem;
    }
    @include media(lg) {
      font-size: 2.0rem;
    }
  }
}

// Copyright
// ---------
.copyright {
  background-color: $base-color;
  color: #fff;
  display: block;
  font-size: 87.5%;
  padding: .6em 0;
  text-align: center;
  width: 100%;
}
