@charset 'UTF-8';

// トップページや下層ページなどで使用する
// ----------------------------

// カレンダー
// -------
.calendar-wrapper {
  margin: 36px 0;

  .calendar-date {
    background: #fff;
    border-left: 4px solid $base-color;
    box-shadow: 0 0 4px rgba(#999, .7);
    color: $gray;
    font-size: 20px;
    margin-bottom: 1em;
    padding: .5em 1em;
  }
}

// Table
// -----
.calendar {
  min-width: 768px;
  table-layout: fixed;
  th {
    background: #EDF7D2;
    font-size: 14px;
    padding: 4px 0;
  }
  td {
    padding: .5em;
    vertical-align: top;
    height: 124px;

    .day {
      font-weight: normal;
    }

    &.current {
      background: #f7fbfc;
      .day {
        font-weight: bold;
      }
    }
  }
}

// 時間と定員数
// ---------
.entry-container,
a .entry-container {
  background: #f9f9f9;
  border: 1px solid $gray-lighter;
  border-radius: 4px;
  color: $gray-dark;
  cursor: pointer;
  margin-top: 12px;
  padding: 6px 8px;
  transition: background $transition;

  .entry-time {
    font-weight: bold;
  }

  .entry-fixed {
    font-size: 14px;
  }

  .entry-status {
    color: $pink-color;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  &:hover {
    background: #f3f3f3;
  }

  &.disable {
    cursor: default;
    color: $gray-light;
    .entry-status {
      color: $gray-light;
    }
    &:hover {
      background: #f9f9f9;
    }
  }
}

// form modal
// ----------
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.form-wrapper {

}

.form-container {
  margin: 0 auto;
  padding: 0 map( $container-padding, sm );
  width: map( $container-width, sm );
  position: relative;
  @include media(md) {
    padding: 0 map( $container-padding, md );
    max-width: map( $container-width, md );
  }
  @include media(lg) {
    padding: 0 map( $container-padding, lg );
    max-width: 76.8rem;
  }
}

// タイトル
.form-entry-title {
  background: $gray-lightest;
  font-size: 1.4em;
  margin: 2em 0 1em;
  padding: .8em;
  @include media(md) {
    margin: 2em 0;
  }
}

// 日付
.form-date {
  font-size: 1.8rem;
}
// form
form.form-entry {

  .label-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    label {
      &.default {
        border-left: 4px solid $base-color;
        font-size: 1.3rem;
        font-weight: bold;
        margin-right: 12px;
        padding-left: 8px;
      }
    }
    label.error {
      background: $red-color;
      color: #fff;
      display: inline-block;
      font-size: 1.3rem;
      padding: 2px 8px;
      animation-name: fadeIn;
      animation-duration: .6s;
    }
  }
}

[name="email"], [name="emailCheck"],
[name="tel"], [name="telCheck"] {
  display: block;
  margin-bottom: .2em;
  width: 100%;
}
[name="age"] {
  margin-right: .2em;
  //width: 8rem;
  width: 100%;
}
[name="lastName"], [name="firstName"] {
  width: 100%;
}

.form-edit-container,
.form-modal-button-container {
  display: flex;
  align-items: center;
}

// ページ遷移時にアニメーション
// -----
body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;  // 一番手前に
  pointer-events: none;  // 他の要素にアクセス可能にするためにポインターイベントは無効に
  opacity: 0; // 初期値 : 透過状態
  transition: opacity .2s ease; // アニメーション時間は 0.8秒
}
body.fadeout::after {
  opacity: 1;
}

// 確認画面
// ------
ul.form-check {
  margin-bottom: 4em;
  width: 100%;
  @include media(md) {
    margin-bottom: 6em;
    padding-left: 3em;
  }
  li {
    display: block;
    margin-bottom: 2em;
    span {
      border-left: 6px solid $base-color;
      color: $base-color;
      display: block;
      font-size: 1em;
      padding-left: .4em;
    }
    div {
      font-size: 1.2em;
      margin-top: .6em;
    }
    @include media(md) {
      display: flex;
      align-items: center;
      span {
        width: 16rem;
      }
      div {
        margin-top: 0;
      }
    }
  }
}
