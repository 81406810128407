@charset 'UTF-8';

// List
// ----
ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    > li:not(:last-child) {
      margin-bottom: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  ul.indent,
  ol.indent {
    margin-left: 1em;
  }
}

// ul の装飾
// -------
ul.disc {
  list-style: disc outside;
  padding-left: 1em;
  & ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.square {
  & li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      content: '■';
      color: $base-color;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  & li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      content: '●';
      color: $base-color;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-flex {
  display: flex;
  flex-flow: row wrap;
  & li {
    & + li::before {
      content: '';
      padding-right: .5em;
      padding-left: .5em;
    }
  }
}

// ol
// --
ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  & ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

// dl
// --
dl {
  dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
}
