@charset 'UTF-8';

//
// Alert Variables
// ----------------
$alert-border: 0px solid $base-color; // alert の border
$alert-border-radius: 0px; // alert の border-radius
$alert-box-shadow: 0 0 4px rgba( $base-color, .5 ); // alert の box-shadow

// Alert
// -----
.alert {
  background: $base-color;
  border: $alert-border;
  border-radius: $alert-border-radius;
  box-shadow: $alert-box-shadow;
  color: #fff;
  padding: 1em;

  // color pattern
  @each $name, $color in $color-pattern {
    &.#{$name} {
      background: $color;
      box-shadow: 0 0 4px rgba( $color, .5 );
    }
  }
}
